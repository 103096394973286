export default [
  {
    field: 'Account ID',
    required: 'Yes',
    requirements: 'Valid account id',
    notes: 'Id of an Account that belongs to a Cardholder',
    example: '67348'
  },
  {
    field: 'External Tag',
    required: 'No',
    requirements: 'Letter, numbers, " ", "-", "." and "_". Max. 255 characters',
    notes:
      'Free form text field that will be stored with value load record to allow for storing additional information.',
    example: 'Order - 22.53_456'
  },
  {
    field: 'Amount',
    required: 'Yes',
    requirements: 'Monetary amount greater than 0',
    notes:
      'Amount passed in unit of program Currency.',
    example: '$10.00 CAD would be 10.00'
  },
  {
    field: 'Message',
    required: 'No',
    requirements: 'Maximum 50 characters.',
    notes: 'Reference to the reason for the load amount onto card',
    example: ''
  }
];
